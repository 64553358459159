import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

class SendMailSuccess extends React.Component {
  handleSubmit() {
    this.props.history.push("/");
  }

  render() {
    return (
      <div className="vertical-center no-bg p-0 m-0 text-center">
        <div className="container pl-5 pr-5 pb-0 mw-600">
          <img
            className="logo-login"
            alt=""
            height={"250em"}
            src="./images/svg/pharmamad_logo.svg"
          />

          <div className="pt-5">
            <span className="fa-stack fa-lg text-success fa-5x mb-3">
              <i className="fa fa-circle fa-stack-2x"></i>
              <i className="fa fa-thumbs-up fa-stack-1x fa-inverse"></i>
            </span>
            <p className="alert alert-success">
              <FormattedMessage id="Password.Reset.Success" />
            </p>
          </div>

          <button
            type="submit"
            onClick={() => this.handleSubmit()}
            className="btn btn-info"
          >
            <FormattedMessage id="Go.Back" />
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SendMailSuccess);
