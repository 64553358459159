import React from "react";
import { connect } from "react-redux";
import { addToken } from "../../actions/passwordRecoveryTokens/passwordRecoveryTokens";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../util/Util";

class MailForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      disabled: false,
      usernameError: null,
      url: null,
      spinner: "fa fa-spinner fa-spin d-none",
      btnMessage: <FormattedMessage id="Send" />,
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.state.disabled || this.hasEmptyField()) return;
    this.setState({ disabled: true });
    this.setState({ spinner: "fa fa-spinner fa-spin" });
    this.setState({ btnMessage: <FormattedMessage id="Plz.Wait" /> });

    // Close the modal
    var successCallback = (response) => {
      if (response.data !== "OK") {
        this.props.history.push("/pwd/error");
      } else {
        this.props.history.push("/pwd/success");
      }
    };

    var data = {
      username: this.state.username,
      url: this.checkValidUsernameForRecovery(this.state.username),
    };

    this.props.onAddToken(data, successCallback);
  }

  // New method to validate full username during recovery process
  checkValidUsernameForRecovery(username) {
    var pattern = "^([a-zA-Z0-9_]{3,})(-+)([a-z0-9]{3,})$";
    var re = new RegExp(pattern).exec(username);
    //console.log(re);
    return re ? re[3] : false;
  }

  hasEmptyField() {
    return Util.emptyString(this.state.username);
  }

  onFieldChange(field, value) {
    this.setState({ [field]: value });

    if (field === "username") {
      // Force this value to be in lowercase
      // value = value.toLowerCase();
      this.setState({ [field]: value });

      if (!this.checkValidUsernameForRecovery(value)) {
        this.setState({
          usernameError: <FormattedMessage id="Invalid.Username.Recovery" />,
        });
        this.setState({ disabled: true });
      } else {
        this.setState({ usernameError: null });
        this.setState({ disabled: false });
      }
    }
  }

  render() {
    return (
      <div className="vertical-center no-bg p-0 m-0 text-center">
        <div className="container pl-5 pr-5 pb-0 mw-600">
          <img
            className="logo-login"
            alt=""
            height={"250em"}
            src="./images/svg/pharmamad_logo.svg"
          />

          <form className="pt-5" onSubmit={(e) => this.handleSubmit(e)}>
            <div className="form-group row">
              <label htmlFor="username">
                <FormattedMessage id="Plz.Write.Username" />
              </label>{" "}
              :
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text bg-light">
                    <i className="fa fa-user-o fa-fw"></i>
                  </div>
                </div>
                <input
                  type="text"
                  id="username"
                  name="username"
                  className="form-control"
                  value={this.state.username}
                  onChange={(e) =>
                    this.onFieldChange("username", e.target.value.trim())
                  }
                  placeholder={this.props.intl.formatMessage({
                    id: "Username.Connexion",
                  })}
                />
              </div>
            </div>

            <div className="form-group">
              <div className="text-danger">
                <small>{this.state.usernameError}</small>
              </div>
            </div>

            <button
              type="submit"
              className="btn btn-info"
              disabled={this.state.disabled || this.hasEmptyField()}
            >
              <i id="spinner" className={this.state.spinner}></i>{" "}
              {this.state.btnMessage}
            </button>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddToken: (data, successCallback, failureCallback) =>
      dispatch(addToken(data, successCallback, failureCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(MailForm));
