import Util from "./util/Util";
const rootUrl = Util.checkHttps(process.env.REACT_APP_BE_URL);

var APIUrl = {
  // Authentication
  login: rootUrl + "/login", // POST {username, password}
  logout: rootUrl + "/logout", // GET

  // User
  getUser: rootUrl + "/user", // GET

  // Password forgotten
  addToken: rootUrl + "/passwordrecoverytokens/add", // POST {email}
  resetPassword: rootUrl + "/passwordrecoverytokens/resetpassword/", // POST {password}
};

var sessionJWT = sessionStorage.getItem("jwt");
if (sessionJWT) APIUrl.jwtToken = sessionJWT;

export default APIUrl;
