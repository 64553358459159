import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

class SendMailSuccess extends React.Component {
  handleSubmit() {}

  render() {
    return (
      <div className="vertical-center no-bg p-0 m-0 text-center">
        <div className="container pl-5 pr-5 pb-0 mw-600">
          <img
            className="logo-login"
            alt=""
            height={"250em"}
            src="./images/svg/pharmamad_logo.svg"
          />

          <div className="pt-3">
            <span className="fa-stack fa-lg text-success fa-5x mb-3">
              <i className="fa fa-circle fa-stack-2x"></i>
              <i className="fa fa-envelope-o fa-stack-1x fa-inverse"></i>
            </span>
            <p className="alert alert-success">
              <FormattedMessage id="Send.Mail.Success" />
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SendMailSuccess);
