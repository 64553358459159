import React from "react";
import { connect } from "react-redux";
import { login } from "../../actions/authentication/authentication";
import { FormattedMessage, injectIntl } from "react-intl";
import { getUser } from "../../actions/user/user";
import axios from "axios";
import { Link } from "react-router-dom";
import Roles from "../../enums/Roles";
import APIUrl from "../../APIUrl";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import PopoverHelper from "../sub/PopoverHelper";
import Util from "../../util/Util";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username:
        localStorage.getItem("remember_me") === "true"
          ? localStorage.getItem("username")
          : "",
      password: "",
      remember_me:
        localStorage.getItem("remember_me") === "true" ? true : false,
      passwordBtn: "fa fa-eye-slash",
      passwordFieldType: "password",
      check_connectivity:
        localStorage.getItem("check_connectivity") === "true"
          ? true
          : localStorage.getItem("check_connectivity") === "false"
            ? false
            : process.env.REACT_APP_CHECK_CONNECTIVITY &&
                process.env.REACT_APP_CHECK_CONNECTIVITY === "true"
              ? true
              : false,
    };

    // User might have been redirected from PHARMA URL. Get jwt and authenticate
    if (this.props.match.params.jwtToken) {
      var token = this.props.match.params.jwtToken;

      // Set token in headers
      axios.defaults.headers["jwtToken"] = token;
      APIUrl.jwtToken = token;

      // Try to get user to see if token is legit. If so, redirect to homepage
      this.props.onGetUser(() => {
        // Check if JWT token is already set.
        // If not, we store it before redirecting in order to allow F5 refresh in the application without having to login again
        /*
                if (!sessionStorage.getItem('jwt')) {
                    sessionStorage.setItem('jwt', token);
                }
                */
        this.redirectToApp(this.props.user);
      });
    }
  }

  redirectToApp(user) {
    if (
      user.access_pharmamad ||
      user.access_module_home ||
      user.access_module_desk ||
      user.access_module_store
    )
      window.location.replace(
        Util.checkHttps(process.env.REACT_APP_PHARMA_URL) + APIUrl.jwtToken,
      );
  }

  // Update fields
  onChange = (field, value) => {
    this.setState({ [field]: value });

    if (field === "check_connectivity") {
      localStorage.setItem("check_connectivity", value);
      window.location.reload(false);
    }
  };

  // Allow to unmask the password for verification
  displayPassword = (e) => {
    if (e.type === "mouseover") {
      this.setState({
        passwordBtn: "fa fa-eye text-danger",
        passwordFieldType: "text",
      });
    } else {
      this.setState({
        passwordBtn: "fa fa-eye-slash",
        passwordFieldType: "password",
      });
    }
  };

  handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    let that = this;

    const onLoginSuccess = (user) => {
      // Check "remember me" function for login
      if (this.state.remember_me) {
        localStorage.setItem("remember_me", this.state.remember_me);
        localStorage.setItem("username", this.state.username);
      } else {
        localStorage.removeItem("remember_me");
        localStorage.removeItem("username");
      }

      // Disable superadmin access. Allow only other roles
      if (user.role !== Roles.SUPER_ADMIN) {
        const urlParams = new URLSearchParams(this.props.location.search);

        if (urlParams.get("redirect")) {
          const redirectUrl = new URL(urlParams.get("redirect"));
          redirectUrl.searchParams.append("token", APIUrl.jwtToken);
          window.location.replace(redirectUrl.href);
        } else {
          this.redirectToApp(user);
        }
      } else {
        that.props.history.push("/logout");
      }
    };

    const onLoginFail = (error) => {
      switch (error) {
        case "Network Error":
          NotificationManager.error(
            this.props.intl.formatMessage({ id: "Network.Error" }),
          );
          break;
        case "EmptyCredentials":
          NotificationManager.info(
            this.props.intl.formatMessage({ id: "Login.EmptyCredentials" }),
          );
          break;
        case "Too much requests":
          NotificationManager.error(
            this.props.intl.formatMessage({ id: "Login.TooMuchRequests" }),
          );
          break;
        default:
          NotificationManager.error(
            this.props.intl.formatMessage({ id: "Login.Fail" }),
          );
          break;
      }
    };

    // Send login information and redirect on success
    this.props.onLogin(
      e.target.username.value,
      e.target.password.value,
      onLoginSuccess,
      onLoginFail,
    );
  }

  // Display a "maintenance mode" landing page if maintenance mode is on
  setMaintenanceMode() {
    return (
      <div className="alert alert-warning text-center">
        <i className="fa fa-spinner fa-pulse fa-3x fa-fw mb-3"></i>
        <p>
          <strong>{<FormattedMessage id="Maintenance.Message.Title" />}</strong>
          <br />
          {<FormattedMessage id="Maintenance.Message.Content" />}
        </p>
      </div>
    );
  }

  // Login form
  displayLoginForm() {
    return (
      <form onSubmit={(e) => this.handleSubmit(e)}>
        <div className="form-group row mt-5">
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text bg-light">
                <i className="fa fa-user-o fa-fw"></i>
              </div>
            </div>
            <input
              type="text"
              id="username"
              name="username"
              className="form-control"
              autoComplete="username"
              placeholder={this.props.intl.formatMessage({
                id: "Username.Connexion",
              })}
              value={this.state.username}
              onChange={(e) =>
                this.onChange(e.target.id, e.target.value.trim())
              }
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text bg-light">
                <i className="fa fa-key fa-fw"></i>
              </div>
            </div>
            <input
              type={this.state.passwordFieldType}
              className="form-control"
              id="password"
              autoComplete="current-password"
              name="password"
              placeholder={this.props.intl.formatMessage({ id: "Password" })}
              value={this.state.password}
              onChange={(e) =>
                this.onChange(e.target.id, e.target.value.trim())
              }
            />
            <div className="input-group-append">
              <div
                className="btn"
                onMouseOver={(e) => this.displayPassword(e)}
                onMouseOut={(e) => this.displayPassword(e)}
              >
                <i className={this.state.passwordBtn}></i>
              </div>
            </div>
          </div>
        </div>

        <div className="form-group mb-0">
          <div className="form-check text-muted">
            <input
              type="checkbox"
              className="form-check-input"
              name="remember_me"
              id="remember_me"
              defaultChecked={this.state.remember_me}
              onChange={(e) => this.onChange(e.target.id, e.target.checked)}
            />{" "}
            <label className="form-check-label" htmlFor="remember_me">
              {this.props.intl.formatMessage({ id: "Remember.Me" }) +
                this.props.intl
                  .formatMessage({ id: "Username.Connexion" })
                  .toLowerCase()}
            </label>
            <PopoverHelper
              content={<FormattedMessage id="Popover.Content.Remember.Me" />}
            />
          </div>
        </div>

        {/*<div className="form-group">
                <div className="form-check text-muted">
                    <input type="checkbox" className="form-check-input" name="check_connectivity" id="check_connectivity" defaultChecked={this.state.check_connectivity} onChange={(e) => this.onChange(e.target.id, e.target.checked)} /> <label className="form-check-label" htmlFor="check_connectivity">{this.props.intl.formatMessage({ id: "Check.Connectivity" })}</label>
                    <PopoverHelper trigger="hover" content={<FormattedMessage id="Popover.Content.Internet.Check" />} />
                </div>
    </div> */}

        <div className="row mt-4">
          <div className="m-auto">
            <button type="submit" className="btn btn-info">
              <FormattedMessage id="Connect" />
            </button>
          </div>
        </div>
        <p className="clear text-center">
          <Link
            rel="noopener noreferrer"
            to="/pwd/form"
            target="_blank"
            className="forgotPassword"
          >
            <FormattedMessage id="Forgot.Password" />
          </Link>
          {" - "}
          <a
            className="forgotPassword"
            href="https://kb.e4-bizz.com/se-connecter-web"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FormattedMessage id="Online.Help" />
          </a>
        </p>
      </form>
    );
  }

  // Display a "please wait" notice when landing on login form page
  waitForDisplay() {
    return (
      <div className="alert alert-info text-center">
        <i className="fa fa-spinner fa-pulse fa-3x fa-fw mb-3"></i>
        <p>
          <strong>{<FormattedMessage id="Loading.In.Progress" />}</strong>
        </p>
      </div>
    );
  }

  render() {
    let currentRender;

    if (process.env.REACT_APP_MAINTENANCE_MODE === "true") {
      currentRender = this.setMaintenanceMode();
    } else {
      // User might have been redirected from PHARMA URL. Get jwt and authenticate
      currentRender = this.props.match.params.jwtToken
        ? this.waitForDisplay()
        : this.displayLoginForm();
    }

    return (
      <>
        <div className="vertical-center no-bg p-0 m-0">
          <div className="container pt-5 pl-5 pr-5 pb-0 mw-600">
            <img
              className="logo-login"
              alt=""
              height={"250em"}
              src={"./images/svg/pharmamad_logo.svg"}
            />

            {currentRender}
          </div>
        </div>
        <div className="text-center text-muted">
          <small>version {process.env.REACT_APP_VERSION}</small>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (username, password, successCallback, failureCallback) =>
      dispatch(login(username, password, successCallback, failureCallback)),
    onGetUser: (callback) => dispatch(getUser(callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Login));
