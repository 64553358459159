import React, { Component } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import LoginForm from "./components/authentication/LoginForm";
import MailForm from "./components/password/MailForm";
import SendMailSuccess from "./components/password/SendMailSuccess";
import SendMailError from "./components/password/SendMailError";
import NewPwdForm from "./components/password/NewPwdForm";
import PwdResetSuccess from "./components/password/PwdResetSuccess";
import ErrorModal from "./components/sub/modals/ErrorModal.js";
import NetworkModal from "./components/sub/modals/NetworkModal.js";
import NotFound from "./components/NotFound";
import axios from "axios";
import APIUrl from "./APIUrl";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { logout } from "./actions/authentication/authentication";
import { NotificationContainer } from "react-notifications";

// Configure Axios for CORS requests
axios.defaults.baseURL = APIUrl.rootUrl;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.withCredentials = true;

// Used to keep connexion alive (using page refresh)
var sessionJWT = sessionStorage.getItem("jwt");
if (sessionJWT) {
  axios.defaults.headers.common["jwtToken"] = sessionJWT;
  APIUrl.jwtToken = sessionJWT;
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      NetworkModal: <NetworkModal />,
    };

    // Using interceptors for cross-request handling of errors
    axios.interceptors.response.use(undefined, (err) => {
      // If we get a 500 from server stop the chain
      if (err.response && err.response.status === 500) {
        this.openErrorModal();
        return new Promise(() => {});
      } else if (err.response && err.response.status === 403) {
        if (err.response.data.message === "token ip mismatch") {
          sessionStorage.removeItem("jwt");
          this.openErrorModal(
            <FormattedMessage id="Token.Mismatch.Error.Title" />,
            <FormattedMessage id="Token.Mismatch.Error.Content" />,
          );
          return new Promise(() => {});
        } else {
          const hash = window.location.hash;
          if (hash !== "/" && hash !== "/#" && hash !== "#/" && hash !== "/#/")
            window.location.href = "/";
        }
      } else {
        if (!!err.isAxiosError && !err.response) {
          this.setState({
            modal: (
              <NetworkModal
                mode="modal"
                close={() => {
                  this.setState({ modal: null });
                }}
              />
            ),
          });
        }

        throw err;
      }
    });
  }

  logout() {
    function onLogoutSuccess() {
      window.location.href = "/#/";
    }

    // Remove the modal, logout and redirect on success
    this.setState({ modal: null }, () => this.props.onLogout(onLogoutSuccess));
  }

  openErrorModal() {
    var errorModalTitle = <FormattedMessage id="Unexpected.Error" />;
    var errorModalContent = <FormattedMessage id="Unexpected.Error.Expl" />;

    this.setState({
      modal: (
        <ErrorModal
          isOpen={true}
          title={errorModalTitle}
          content={errorModalContent}
          closeModal={(e) => this.logout()}
        />
      ),
    });
  }

  render() {
    return (
      <React.Fragment>
        {this.state.NetworkModal}
        <Router basename={"/"} refresh={true}>
          <Switch>
            <Route
              path="/resetpassword/:token/:company"
              component={NewPwdForm}
            />
            <Route path="/reset/success" component={PwdResetSuccess} />
            <Route path="/pwd/success" component={SendMailSuccess} />
            <Route path="/pwd/error" component={SendMailError} />
            <Route path="/pwd/form" component={MailForm} />
            <Route exact path="/:jwtToken?/:url?" component={LoginForm} />
            <Route component={NotFound} />
          </Switch>
        </Router>

        {this.state.modal}
        <NotificationContainer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.user.lang,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: (successCallback) => dispatch(logout(successCallback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
