import APIUrl from "../../APIUrl";
import axios from "axios";

export const GET_USER = "GET_USER";

export function getUserAction(user) {
  return { type: GET_USER, user: user };
}

export const getUser = function (callback) {
  return function (dispatch) {
    return axios
      .get(APIUrl.getUser)
      .then(function (response) {
        if (!response || !response.status) window.location.href = "/#/";

        dispatch(getUserAction(response.data));
        if (callback) callback();
      })
      .catch(function (err) {
        window.location.href = "/#/";
      });
  };
};
